<template>
  <component
    :is="type"
    :to="to ? to : null"
    :href="href ? href : null"
    class="icon-btn"
    :class="{
      'icon-btn--green': green,
      'icon-btn--red': red,
      'icon-btn--beige': beige,
      'icon-btn--black': black,
      'icon-btn--gray': gray,
      'icon-btn--white': white,
      'icon-btn--medium': medium,
      'icon-btn--small': small,
      'icon-btn--tiny': tiny,
    }"
  >
    <span v-if="icon" class="icon-btn__icon" :class="[`icon-${icon}`]"></span>
  </component>
</template>

<script setup lang="ts">
const store = useDefaultStore();
const shopwareStore = useShopwareStore();

const props = defineProps({
  type: {
    default: 'div',
    type: String,
  },
  to: {
    type: String,
    default: '',
  },
  href: {
    type: String,
    default: '',
  },
  green: {
    type: Boolean,
    default: false,
  },
  beige: {
    type: Boolean,
    default: false,
  },
  red: {
    type: Boolean,
    default: false,
  },
  black: {
    type: Boolean,
    default: false,
  },
  gray: {
    type: Boolean,
    default: false,
  },
  white: {
    type: Boolean,
    default: false,
  },
  medium: {
    type: Boolean,
    default: false,
  },
  small: {
    type: Boolean,
    default: false,
  },
  tiny: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: 'arrow-right',
  },
});
</script>

<style lang="scss">
.icon-btn {
  width: 4rem;
  height: 4rem;
  flex: 0 0 4rem;
  border-radius: 50%;
  background: $color-white;
  position: relative;

  &--medium {
    width: 2.75rem;
    height: 2.75rem;
    flex: 0 0 2.75rem;
  }
  &--small {
    width: 2rem;
    height: 2rem;
    flex: 0 0 2rem;
  }
  &--tiny {
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
  }

  &--green {
    background: $color-green;
    color: $color-white;
  }
  &--red {
    background: $color-red;
    color: $color-white;
  }
  &--beige {
    background: $color-beige;
  }
  &--black {
    background: $color-black;
    color: $color-white;
  }
  &--blue {
    background: $color-blue;
    color: $color-white;
  }
  &--gray {
    background: $color-light-gray;
  }
}

.icon-btn__icon {
  @include absolute-center;
}
</style>
